import { DocumentPlusIcon } from '@heroicons/react/24/solid';
import React, { ChangeEvent, DragEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FILE_TOO_LARGE_ERROR_MESSAGE, LARGE_AVATAR_SIZE_CLASS, MARK_PROFILE_PHOTO, NON_PDF_FILE_ERROR_MESSAGE, ONE_MEGABYTE_IN_BYTES } from '../constants';
import { useAuthStatus, useGoogleSignIn } from '../lib/helpers/auth';
import useStore from '../lib/store';
import Avatar from './shared/Avatar';
import Skeleton from 'react-loading-skeleton';

const UploadResumeView: React.FC = () => {
  const { user, loading } = useAuthStatus();
  const { setUploadedFile } = useStore();
  const signInWithGoogle = useGoogleSignIn();
  const navigate = useNavigate();

  const [isDragOver, setIsDragOver] = useState(false);

  const handleDraggingDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    handleFileUpload(event);
    setIsDragOver(false);
  }

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    let files: FileList | null;
    if (event.type === 'drop') {
      files = (event as DragEvent<HTMLDivElement>).dataTransfer.files;
    } else {
      files = (event as ChangeEvent<HTMLInputElement>).target.files;
    }

    if (files && files[0]) {
      const file = files[0];
      if (file.type === "application/pdf") {
        if (file.size <= ONE_MEGABYTE_IN_BYTES) {
          setUploadedFile(file);
          navigate('/resume'); // Redirect after setting the file
        } else {
          alert(FILE_TOO_LARGE_ERROR_MESSAGE);
        }
      } else {
        alert(NON_PDF_FILE_ERROR_MESSAGE);
      }
    }
  };


  const UPLOAD_CTA = user ?
    `Hey ${user.displayName?.split(' ')[0]}, upload a resume for review!` :
    'Welcome! Which resume can I review for you?'

  return (
    <>
      {/* marks pfp w/ upload resume CTA */}
      <div className="flex flex-col items-center gap-3 max-w-[75%] w-3/4 mt-28">
        <div className={`flex items-center ${LARGE_AVATAR_SIZE_CLASS}`}>
          {loading ? <Skeleton className={LARGE_AVATAR_SIZE_CLASS} circle={true} /> :
            <Avatar
              url={MARK_PROFILE_PHOTO}
              size="large"
              alt="Mark Benliyan"
            />
          }
        </div>
        <div className='flex gap-1 text-xl font-bold cursor-default'>
          {loading ? <Skeleton width={500} containerClassName="flex-1" /> :
            <h1 className='text-center'>
              {UPLOAD_CTA}
            </h1>
          }
        </div>
      </div>
      {/* file uploader */}
      <div className="mt-16 flex justify-center cursor-default">
        <div
          className="flex justify-center rounded-lg px-32 pt-16 pb-24"
          style={{
            boxShadow: 'inset 0 4px 8px 0 rgba(0, 0, 0, 0.2)',
            border: '1px solid #ddd',
            backgroundColor: '#f9f9f9',
            ...(isDragOver ? { boxShadow: 'inset 0 4px 8px 0 lightgreen' } : {})
          }}
          onDrop={handleDraggingDrop}
          onDragOver={handleDragOver}
          onDragLeave={() => setIsDragOver(false)}
        >
          <div className="text-center">
            <DocumentPlusIcon className="mx-auto h-16 w-16 text-gray-300" aria-hidden="true" />
            <div className="mt-4 flex text-lg leading-6 text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>Upload a resume</span>
                <input id="file-upload" name="file-upload" type="file" className="sr-only" accept="application/pdf" onChange={handleFileUpload} />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-md leading-5 text-gray-600">PDF up to 1MB</p>
          </div>
        </div>
      </div>

      {/* Sign in footer */}
      {!user &&
        <div className="mt-4 text-gray-500 text-sm cursor-default">
          Been here before?
          <span className="ml-1 cursor-pointer text-blue-600" onClick={signInWithGoogle}>
            Sign back in
          </span>
        </div>
      }
    </>
  );
};

export default UploadResumeView;
