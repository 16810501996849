/* eslint-disable jsx-a11y/anchor-is-valid */
import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';
import { DEFAULT_PROFILE_PHOTO_URL, MEDIUM_AVATAR_SIZE_CLASS } from '../constants';
import { useAuthStatus, useGoogleSignIn, useSignOut } from '../lib/helpers/auth';
import Avatar from './shared/Avatar';

const CurrentUserIcon: React.FC = () => {
  const { user, loading } = useAuthStatus();
  const signInWithGoogle = useGoogleSignIn();
  const signOut = useSignOut();

  return (
    <Menu as="div" className="relative ml-3">
      <div className={`flex items-center ${MEDIUM_AVATAR_SIZE_CLASS}`}>
        {loading ? <Skeleton className={MEDIUM_AVATAR_SIZE_CLASS} circle={true} /> :
          <Menu.Button className='relative flex rounded-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 items-center'>
            <span className="absolute -inset-1.5" />
            <span className="sr-only">Open user menu</span>
            <Avatar
              url={user?.photoURL || DEFAULT_PROFILE_PHOTO_URL}
              size={'medium'}
              alt={user?.displayName || ''}
            />
          </Menu.Button>
        }
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-2 shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none">
          {user && (
            <div className='flex flex-col items-center'>
              <div className='flex flex-col ml-2 text-center'>
                <p className='text-sm font-semibold text-gray-700'>
                  {user.displayName}
                </p>
                <p className='text-xs text-gray-500'>
                  {user.email}
                </p>
              </div>
              <div className="my-2 border-t border-gray-200 w-full"></div>
            </div>
          )}

          {user && (
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={active ? 'bg-gray-100 block px-4 py-2 text-sm text-gray-700' : 'block px-4 py-2 text-sm text-gray-700'}
                  onClick={signOut}
                >
                  Sign out
                </a>
              )}
            </Menu.Item>
          )}

          {!user && (
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={active ? 'bg-gray-100 block px-4 py-2 text-sm text-gray-700' : 'block px-4 py-2 text-sm text-gray-700'}
                  onClick={signInWithGoogle}
                >
                  Sign In
                </a>
              )}
            </Menu.Item>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};


export default CurrentUserIcon;
