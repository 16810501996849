import React from 'react';

interface AvatarProps {
  url: string;
  size?: 'small' | 'medium' | 'large';
  alt?: string;
}

const Avatar: React.FC<AvatarProps> = ({ url, size, alt }) => {
  let sizeClasses = '';

  switch (size) {
    case 'small':
      sizeClasses = 'h-8 w-8';
      break;
    case 'medium':
      sizeClasses = 'h-10 w-10';
      break;
    case 'large':
      sizeClasses = 'h-20 w-20';
      break;
    default:
      sizeClasses = 'h-8 w-8';
  }

  return (
    <img
      src={url}
      alt={alt || ''}
      className={`rounded-full bg-white border ${sizeClasses}`}
      loading="lazy"
      draggable="false"
      onContextMenu={(e) => e.preventDefault()} // Prevent right-click context menu on the image
      style={{ userSelect: 'none', pointerEvents: 'none' }} // Prevent selection and pointer events on the image

    />
  );
};

export default Avatar;
