import { useLocation } from "react-router-dom";
import useStore from "../store";
import { UPLOAD_RESUME_VIEW_ROUTE } from "../../constants";

export const clearUserState = () => {
  const { setResumeDownloadUrl, setUploadedFile, setResumeComments } = useStore.getState();
  setResumeDownloadUrl('');
  setUploadedFile(null);
  setResumeComments([]);
}

export const useIsUploadResumeView = () => {
  const location = useLocation();
  return location.pathname === UPLOAD_RESUME_VIEW_ROUTE;
}

export const useIsLoadedResumeView = () => {
  const location = useLocation();
  return location.pathname !== UPLOAD_RESUME_VIEW_ROUTE;
}
