// This file contains firebase cloud storage related helper functions, which is where we store files like our resume pdf. 
import { User } from 'firebase/auth';
import { deleteObject, getDownloadURL, getStorage, listAll, ref, uploadBytesResumable } from 'firebase/storage';
import { useEffect, useState } from 'react';

export const useCheckExistingResume = (user: User | null) => {
  const [hasExistingResume, setHasExistingResume] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (user) {
      const checkIfUserHasExistingResume = async () => {
        const storage = getStorage();
        const userFolderRef = ref(storage, `resumes/${user.uid}`);

        try {
          const res = await listAll(userFolderRef);
          setHasExistingResume(res.items.length > 0);
        } catch (err) {
          console.error('Error checking for existing resume:', err);
          setError(err as Error);
        } finally {
          setIsLoading(false);
        }
      };

      checkIfUserHasExistingResume();
    } else {
      setIsLoading(false);
    }
  }, [user]);

  return { hasExistingResume, isLoading, error };
};

// Function to get the existing resume URL for a given user
export const getExistingResumeUrl = (user: User): Promise<string> => {
  const storage = getStorage();
  const userFolderRef = ref(storage, `resumes/${user.uid}`);

  return listAll(userFolderRef).then((res) => {
    if (res.items.length > 0) {
      return getDownloadURL(res.items[0]);
    } else {
      throw new Error('No resume found');
    }
  });
};

// Function to delete all existing resumes for a given user
export const deleteExistingResumesFromCloudStorage = (user: User): Promise<void[]> => {
  const storage = getStorage();
  const userFolderRef = ref(storage, `resumes/${user.uid}`);

  return listAll(userFolderRef).then((res) => {
    return Promise.all(res.items.map(itemRef => deleteObject(itemRef)));
  });
};

// Function to upload file to Firebase Cloud Storage
export const uploadResumeToCloudStorage = (file: File, user: User): Promise<string> => {
  return new Promise((resolve, reject) => {
    const storage = getStorage();
    const storagePath = `resumes/${user.uid}/${file.name}`;
    const storageRef = ref(storage, storagePath);

    const metadata = {
      contentType: file.type,
      customMetadata: {
        'userId': user.uid,
        'userName': user.displayName || '',
        'userEmail': user.email || ''
      }
    };

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on('state_changed',
      (snapshot) => {
        // You can handle progress here if you need to
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error('Error uploading file:', error);
        reject(error);
      },
      () => {
        // Handle successful uploads
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
};
