import { useNavigate } from 'react-router-dom';
import { useIsLoadedResumeView, useIsUploadResumeView } from '../lib/helpers/appState';
import { useAuthStatus } from '../lib/helpers/auth';
import CurrentUserIcon from './CurrentUserIcon';
import ProjectName from './ProjectName';
import { useCheckExistingResume } from '../lib/helpers/cloudStorage';

const Header: React.FC = () => {
  const { user } = useAuthStatus();
  const navigate = useNavigate();

  const isUploadResumeView = useIsUploadResumeView();
  const isLoadedResumeView = useIsLoadedResumeView();
  const { hasExistingResume } = useCheckExistingResume(user);


  const handleUploadNewResume = () => {
    navigate('/');
  }

  const handleSeeLastReview = () => {
    navigate('/resume');
  }



  return (
    <div className="w-full hidden justify-between md:flex px-5 pt-3 md:flex-shrink-0 md:items-center h-14 max-h-{52}">
      <ProjectName />
      <div className="flex gap-4">
        {isLoadedResumeView &&
          <button className="bg-gray-100 text-sm text-gray-500 hover:bg-gray-200 hover:text-gray-600 border border-gray-400 px-2 py-1 rounded transition duration-150 ease-in-out h-10" onClick={handleUploadNewResume}>
            Upload New Resume
          </button>
        }
        {/* Authenticated user's can see their last review */}
        {isUploadResumeView && user && hasExistingResume &&
          < button className="bg-gray-100 text-sm text-gray-500 hover:bg-gray-200 hover:text-gray-600 border border-gray-400 px-2 py-1 rounded transition duration-150 ease-in-out h-10" onClick={handleSeeLastReview}>
            See Last Review
          </button>
        }
        <CurrentUserIcon />
      </div>
    </div >
  );
};

export default Header;