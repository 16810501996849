import 'react-loading-skeleton/dist/skeleton.css';
import { Route, Routes, useNavigate } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './components/Header';
import LoadedResumeView from './components/LoadedResumeView';
import UploadResumeView from './components/UploadResumeView';
import { LOADED_RESUME_VIEW_ROUTE, UPLOAD_RESUME_VIEW_ROUTE } from './constants';
import { useAuthStatus } from './lib/helpers/auth';


const App: React.FC = () => {
  const [initialLoad, setInitialLoad] = useState(true);
  const { user } = useAuthStatus();
  const navigate = useNavigate();

  useEffect(() => {
    // First time user loads site, redirect to /resume if they're authenticated
    if (user && initialLoad) {
      navigate(LOADED_RESUME_VIEW_ROUTE);
      setInitialLoad(false);
    }

    // Function to prevent drag and drop from opening the file in the browser
    const preventDragHandler = (e: globalThis.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    // Add event listeners for drag and drop
    window.addEventListener('dragenter', preventDragHandler);
    window.addEventListener('dragover', preventDragHandler);
    window.addEventListener('drop', preventDragHandler);

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('dragenter', preventDragHandler);
      window.removeEventListener('dragover', preventDragHandler);
      window.removeEventListener('drop', preventDragHandler);
    };
  }, [user, initialLoad, navigate]);



  return (
    <div className='flex flex-col w-screen h-screen bg-gray-50 items-center'>
      <Header />
      <Routes>
        <Route path={UPLOAD_RESUME_VIEW_ROUTE} element={<UploadResumeView />} />
        <Route path={LOADED_RESUME_VIEW_ROUTE} element={<LoadedResumeView />} />
      </Routes>
    </div>
  );
}

export default App;
