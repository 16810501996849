// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
import { getFirestore } from "firebase/firestore";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDOkspeKDssUfPt7B8T_62hO3bwSSatmhM',
  authDomain: 'resume-proj-f2a4b.firebaseapp.com',
  projectId: 'resume-proj-f2a4b',
  storageBucket: 'resume-proj-f2a4b.appspot.com',
  messagingSenderId: '1018266777124',
  appId: '1:1018266777124:web:5191ab1c7370ffabbdcab1',
  measurementId: 'G-VFTEYXG2VQ',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Initialize Cloud Storage and get a reference to the service
const storage = getStorage(app);
// Initialize Firestore and get a reference to the service
const firestoreRef = getFirestore(app);

export { app, analytics, storage, firestoreRef }; // Export firestore instance
