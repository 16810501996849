import { User } from 'firebase/auth';
import { FieldValue, Timestamp, addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, serverTimestamp, setDoc, where } from 'firebase/firestore';
import { MARK_BENLIYAN } from '../../constants';
import { firestoreRef } from '../firebase';

export interface UserData {
  uid: string;
  name: string;
  email: string;
}

export interface ResumeData {
  url: string;
  createdAt: FieldValue | Timestamp; 
  uid: string;
  fileName: string;
}

// When writing to Firestore
interface NewCommentData {
  text: string;
  author: string;
  createdAt: FieldValue; // Use FieldValue for writing
}

// When reading from Firestore
export interface RetrievedCommentData {
  text: string;
  author: string;
  createdAt: Timestamp; // Use Timestamp for reading
}


const createUserDirectory = async (user: User): Promise<void> => {
  const userData: UserData = {
    uid: user.uid,
    name: user.displayName || '',
    email: user.email || ''
  };
  const userRef = doc(firestoreRef, 'users', userData.uid);

  await setDoc(userRef, userData);
};

const createResume = async (user: User, url: string, fileName: string): Promise<string> => {
  const resumeData: ResumeData = {
    url: url,
    createdAt: serverTimestamp(), // This will be converted to a Firestore timestamp
    uid: user.uid,
    fileName: fileName
  };

  const docRef = await addDoc(collection(firestoreRef, 'users', user.uid, 'resumes'), resumeData);
  // Return the ID of the newly created resume
  return docRef.id; 

};

const addCommentToResume = async (user: User, resumeId: string): Promise<void> => {
  const commentData: NewCommentData = {
    text: 'This is a comment (retrieved from Firestore)',
    author: MARK_BENLIYAN,
    createdAt: serverTimestamp()
  }
  await addDoc(collection(firestoreRef, 'users', user.uid, 'resumes', resumeId, 'comments'), commentData);
};

const deleteExistingResumesAndComments = async (user: User): Promise<void> => {
  const resumesRef = collection(firestoreRef, 'users', user.uid, 'resumes');
  const resumesSnapshot = await getDocs(query(resumesRef));

  for (const resumeDoc of resumesSnapshot.docs) {
    // Delete all comments in the resume's subcollection
    const commentsRef = collection(resumeDoc.ref, 'comments');
    const commentsSnapshot = await getDocs(query(commentsRef));

    const commentDeletePromises = commentsSnapshot.docs.map(doc => deleteDoc(doc.ref));
    await Promise.all(commentDeletePromises);

    // Delete the resume document itself
    await deleteDoc(resumeDoc.ref);
  }
};

export const getCommentsForResumeByUrl = async (user: User, url: string): Promise<RetrievedCommentData[]> => {
  const resumesRef = collection(firestoreRef, 'users', user.uid, 'resumes');
  const q = query(resumesRef, where("url", "==", url));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const resumeDoc = querySnapshot.docs[0]; // Assuming URL is unique and fetches only one document
    const resumeId = resumeDoc.id;
    const commentsRef = collection(firestoreRef, 'users', user.uid, 'resumes', resumeId, 'comments');
    const commentsSnapshot = await getDocs(query(commentsRef));
    
    return commentsSnapshot.docs.map(doc => doc.data() as RetrievedCommentData);
  } else {
    // Handle the case where no resume is found for the given URL
    console.log('No resume found for the given URL');
    return [];
  }
};

// Function to upload resume data to Firestore
export const syncWithFirestore = async (user: User, url: string, fileName: string): Promise<void> => {
  const userRef = doc(firestoreRef, 'users', user.uid);
  const userDir = await getDoc(userRef);

  // create user directory if it doesn't exist yet
  if (!userDir.exists()) {
    await createUserDirectory(user);
  }

  // clear out existing resumes
  await deleteExistingResumesAndComments(user);

  // Capture the resumeId returned from createResume
  const resumeId = await createResume(user, url, fileName);

  // NOTE / TODO: currently hardcoding the addition of 3 comments w/ every resume
  const NUM_COMMENTS_TO_ADD = 3;
  for (let i = 0; i < NUM_COMMENTS_TO_ADD; i++)
    await addCommentToResume(user, resumeId);
};
