import { create } from 'zustand';
import { RetrievedCommentData } from './helpers/firestore';

interface ResumeStoreState {
  resumeDownloadUrl: string;
  setResumeDownloadUrl: (url: string) => void;
  uploadedFile: File | null;
  setUploadedFile: (file: File | null) => void;
  resumeComments: RetrievedCommentData[];
  setResumeComments: (comments: RetrievedCommentData[]) => void;
}

const useStore = create<ResumeStoreState>((set) => ({
  resumeDownloadUrl: '',
  setResumeDownloadUrl: (url: string) => set({ resumeDownloadUrl: url }),
  uploadedFile: null,
  setUploadedFile: (file: File | null) => set({ uploadedFile: file }),
  resumeComments: [],
  setResumeComments: (comments: RetrievedCommentData[]) => set({ resumeComments: comments }),
}));

export default useStore;
