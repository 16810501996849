import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProjectName: React.FC = () => {
  const navigate = useNavigate();

  const returnToHome = () => {
    navigate('/');
  }

  return (
    <div className='flex gap-1 text-lg font-bold cursor-default' onClick={returnToHome}>
      <h1>Mark's Resume Reviews</h1>
      <div className='bg-yellow-100 mt-0.5 flex items-center rounded py-0.5 px-1 h-fit flex-start'>
        <p className='text-red-600 text-xs'>BETA</p>
      </div>
    </div>
  );
};

export default ProjectName;
