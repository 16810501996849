// Overlay.tsx
import React from "react";

interface OverlayProps {
  children?: React.ReactNode;
  isShowing: boolean;
}

const Overlay: React.FC<OverlayProps> = ({ children, isShowing }) => {
  if (!isShowing) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 flex justify-center items-center z-50">
      {children}
    </div>
  );
};

export default Overlay;
