// This file contains auth related helper functions
import { GoogleAuthProvider, User, getAuth, onAuthStateChanged, signInWithPopup, signOut } from 'firebase/auth';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { app } from '../firebase';
import { clearUserState } from './appState';

export const useSignOut = () => {
  const navigate = useNavigate();

  const signOutUser = async () => {
    const auth = getAuth(app);

    try {
      
      navigate('/');
      // Clear all items from localStorage
      localStorage.clear();
      clearUserState();
      await signOut(auth);
    } catch (error) {
      console.error(error);
      // Optionally handle errors, such as showing an error message
    }
  };

  return signOutUser;
};


export const useGoogleSignIn = () => {
  const navigate = useNavigate();

  const signInWithGoogle = async () => {
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    try {
      await signInWithPopup(auth, provider);
      navigate('/resume');
    } catch (error) {
      console.error(error);
      // Handle errors here, e.g., show an error message
    }
  };

  return signInWithGoogle;
};

export const useAuthStatus = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return { user, loading };
};
