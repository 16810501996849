import { Timestamp } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { useAuthStatus } from '../lib/helpers/auth';
import { deleteExistingResumesFromCloudStorage, getExistingResumeUrl, uploadResumeToCloudStorage, useCheckExistingResume } from '../lib/helpers/cloudStorage';
import { RetrievedCommentData, getCommentsForResumeByUrl, syncWithFirestore } from '../lib/helpers/firestore';
import useStore from '../lib/store';
import Comment from './Comment';
import LoginOverlay from './LoginOverlay';

const formatFirestoreTimestamp = (timestamp: Timestamp): string => {
  // Convert Firestore Timestamp to JavaScript Date object
  const date = timestamp.toDate();

  // Define formatting options
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: true
  };

  // Format and return the date
  return date.toLocaleString('en-US', options);
};

// Function to convert raw JSON object to Firestore Timestamp
const convertJsonToTimestamp = (raw: { seconds: number, nanoseconds: number }): Timestamp => {
  return new Timestamp(raw.seconds, raw.nanoseconds);
};

const LoadedResumeView: React.FC = () => {
  const { resumeComments, resumeDownloadUrl, uploadedFile, setResumeDownloadUrl, setResumeComments, setUploadedFile } = useStore();
  const { user, loading } = useAuthStatus();
  const { hasExistingResume } = useCheckExistingResume(user);
  const navigate = useNavigate();

  const [isUpdating, setIsUpdating] = useState(false);

  const USER_INFO_IN_STATE = resumeComments && resumeDownloadUrl;

  //NOTE: if helpful we can create a temporary URL with URL.createObjectURL(uploadedFile)

  useEffect(() => {
    const performAsyncOperations = async () => {
      if (!loading) {
        if (user && !uploadedFile && !hasExistingResume) {
          // CASE 1: User clicks sign in without having an existing account
          console.log('CASE 1: User clicks sign in without having an existing account')
          navigate('/');
        } else if (user && uploadedFile) {
          // CASE 2: Authenticated user uploaded a new resume
          console.log('CASE 2: Authenticated user uploaded a new resume')
          setIsUpdating(true)

          try {
            await deleteExistingResumesFromCloudStorage(user);
            const newResumeUrl = await uploadResumeToCloudStorage(uploadedFile, user);

            await syncWithFirestore(user, newResumeUrl, uploadedFile.name);
            const comments = await getCommentsForResumeByUrl(user, newResumeUrl);

            setResumeDownloadUrl(newResumeUrl);
            setResumeComments(comments);
            setUploadedFile(null);

            // Store in localStorage
            localStorage.setItem('resumeDownloadUrl', newResumeUrl);
            localStorage.setItem('resumeComments', JSON.stringify(comments));
            console.log('stored values in localStorage')
          }
          catch (error) {
            console.error(error);
          }
          finally {
            setIsUpdating(false)
          }
        } else if (user && !uploadedFile) {
          // CASE 3: authenticated users hits /resume without uploading resume (i.e signs back in)
          console.log('CASE 3: Authenticated user hits /resume without uploading resume (i.e signs back in)')

          // We already have the values, no need to access localStorage or Cloud storage again
          if (USER_INFO_IN_STATE) {
            return
          }

          const storedResumeUrl = localStorage.getItem('resumeDownloadUrl');
          const jsonComments = localStorage.getItem('resumeComments');
          let storedComments: RetrievedCommentData[] = [];

          if (jsonComments) {
            const rawComments = JSON.parse(jsonComments);

            const formattedComments: RetrievedCommentData[] = rawComments.map((comment: any) => ({
              text: comment.text,
              author: comment.author,
              createdAt: convertJsonToTimestamp(comment.createdAt)
            }));

            storedComments = formattedComments;
          }

          // Check if data is available in localStorage
          if (storedResumeUrl && storedComments) {
            console.log('fetched values from local Storage ', storedResumeUrl, storedComments)
            setResumeDownloadUrl(storedResumeUrl);
            setResumeComments(storedComments);
            // No need to set updating state or fetch data, so return early
            return;
          }

          setIsUpdating(true)

          try {
            const resumeUrl = await getExistingResumeUrl(user);
            const comments = await getCommentsForResumeByUrl(user, resumeUrl);

            setResumeDownloadUrl(resumeUrl);
            setResumeComments(comments);

            // Also store these in localStorage for future sessions
            localStorage.setItem('resumeDownloadUrl', resumeUrl);
            localStorage.setItem('resumeComments', JSON.stringify(comments));

          }
          catch (error) {
            console.error(error);
          }
          finally {
            setIsUpdating(false)
          }
        } else if (!user && !uploadedFile) {
          // CASE 4: User tries hitting /resume directly without uploading resume
          console.log('CASE 4: Unauthenticated user tries hitting /resume manually without uploading resume')
          navigate('/');
        } else {
          // CASE 5: Unauthenticated user hits /resume after uploading resume -- standard new user experience. LoginOverlay will render, the user will sign in, then we'll hit Case 1!
          console.log('CASE 5: Unauthenticated user hits /resume after uploading resume -- standard new user experience')
        }
      }
    };

    performAsyncOperations();
  }, [user, uploadedFile, loading, navigate, setResumeDownloadUrl, setResumeComments, USER_INFO_IN_STATE, setUploadedFile, hasExistingResume]);

  const showSkeletons = isUpdating || loading

  return (
    <div className='my-4 h-screen w-full px-12 flex gap-4'>
      <LoginOverlay />
      {/* Renders PDF via generated URL */}
      <div className='w-3/4'>
        {showSkeletons ? <Skeleton containerClassName="flex-1" height='100%' /> :
          <object
            className="col-span-2"
            width="100%"
            height="100%"
            data={resumeDownloadUrl}
            type="application/pdf">
          </object>
        }
      </div>

      {/* Comments list */}
      <div className='flex flex-col w-1/4 gap-4 mt-4 ml-4'>
        {showSkeletons ? <Skeleton containerClassName="flex-1 flex flex-col" height='15%' count={4} /> :
          resumeComments.map((comment, index) => (
            <div
              key={index}
              className="comment-enter"
              style={{ animationDelay: `${index * 1}s` }}
            >
              <Comment
                text={comment.text}
                createdAt={formatFirestoreTimestamp(comment.createdAt)}
              />
            </div>
          ))
        }

      </div>
    </div>
  );
};

export default LoadedResumeView;
