/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { MARK_BENLIYAN, MARK_PROFILE_PHOTO } from '../constants';
import Avatar from './shared/Avatar';

interface CommentProps {
  text: string;
  createdAt: string;
  author?: string;
}

const Comment: React.FC<CommentProps> = ({ text, createdAt }) => {
  return (
    <div className="gap-4 p-4 border rounded-lg bg-white shadow-lg">
      <div className="flex gap-2 items-center">
        <Avatar
          url={MARK_PROFILE_PHOTO}
          size="small"
          alt={MARK_BENLIYAN}
        />
        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between">
            <p className="text-sm font-semibold whitespace-nowrap truncate overflow-hidden">{MARK_BENLIYAN}</p>
          </div>
          <p className="text-gray-400 text-xs">{createdAt}</p>
        </div>
      </div>
      <p className="text-gray-700 mt-1">{text}</p>
    </div>
  );
};

export default Comment;
