export const MARK_PROFILE_PHOTO: string = '/mark-pfp.png'
export const MARK_BENLIYAN: string = 'Mark Benliyan'
export const DEFAULT_PROFILE_PHOTO_URL = "https://www.lightsong.net/wp-content/uploads/2020/12/blank-profile-circle.png"
export const UPLOAD_RESUME_VIEW_ROUTE = '/'
export const LOADED_RESUME_VIEW_ROUTE = '/resume'
export const ONE_MEGABYTE_IN_BYTES = 1048576
export const SMALL_AVATAR_SIZE_CLASS = 'h-8 w-8'
export const MEDIUM_AVATAR_SIZE_CLASS = 'h-10 w-10'
export const LARGE_AVATAR_SIZE_CLASS = 'h-20 w-20'
export const NON_PDF_FILE_ERROR_MESSAGE = 'Please upload a PDF file.'
export const FILE_TOO_LARGE_ERROR_MESSAGE = 'Please upload a file smaller than 1MB.'